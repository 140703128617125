<!-- 无人机产品专题页面 -->
<template>
    <div class="uavMain">
        <img src="../../assets/equipment/banner.jpg" />
        <div class="banner">
            <img src="../../assets/equipment/slogan.png" width="400" />
            <div class="list">
                <div class="item" v-for="(item,index) in advantage" :key="index">
                    <img :src="item.imgUrl" width="60" />
                    <div class="text">{{item.text1}}</div>
                    <div class="text">{{item.text2}}</div>
                </div>
            </div>
            <div class="c_btn" @click="isShowVideo = true"><i style="margin-right:10px;" class="el-icon-caret-right"></i>观看演示视频</div>
        </div>
        <div class="product">
            <div class="h2">基于无线充电式的无人值守自动机场</div>
            <div class="text">智能无人机AI赋能系统，专注为无人机打造“制能”、“机能”、“算能”、“图能”四个层面的赋能系统。</div>
            <div class="p_list">
                <div class="p_item" v-for="(item,index) in equipmentList" :key="`item${index}`" @click="toDetail(item)">
                    <img :src="item.url[0].httpAccessUrl" />
                    <div class="p">{{item.name}}</div>
                    <div class="des">{{item.content}}</div>
                    <el-button type="primary" plain size="mini">了解详情</el-button>
                </div>
            </div>
        </div>
        <div v-if="isShowVideo">
            <Video @closePop="isShowVideo = false" />
        </div>
    </div>
</template>

<script>
import { equipmentList } from '@/mockData'
import Video from './video.vue'
export default {
    name: "UavTopicPage",
    components: { Video },
    data() {
        return {
            advantage: [
                {
                    imgUrl: require("../../assets/equipment/feature01.png"),
                    text1: "飞控平台可",
                    text2: "适配多厂家机库"
                },
                {
                    imgUrl: require("../../assets/equipment/feature02.png"),
                    text1: "同机库可",
                    text2: "适配多种机型"
                },
                {
                    imgUrl: require("../../assets/equipment/feature03.png"),
                    text1: "支持机库平台及机库",
                    text2: "个性化定制"
                },
                {
                    imgUrl: require("../../assets/equipment/feature04.png"),
                    text1: "支持无人机",
                    text2: "多场景应用定制"
                },
                {
                    imgUrl: require("../../assets/equipment/feature05.png"),
                    text1: "支持室内",
                    text2: "无人机巡检"
                }
            ],
            equipmentList: [],
            isShowVideo: false
        }
    },



    mounted() {
        this.equipmentList = equipmentList
    },

    methods: {
        toDetail(item) {
            this.$router.push({
                path: "/main/equipment/equipmentDetail",
                query: {
                    id: item.id
                }
            })
        }
    }
}

</script>
<style lang='less' scoped>
.uavMain {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    position: relative;
    > img {
        width: 100%;
        display: block;
    }
    .banner {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;
        .list {
            display: flex;
            margin-top: 30px;
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0 10px;
                > .text {
                    font-size: 14px;
                    color: #fff;
                    margin-top: 6px;
                }
            }
        }
    }
    .c_btn {
        cursor: pointer;
        width: 140px;
        height: 32px;
        border-radius: 32px;
        font-size: 14px;
        color: #fff;
        border: 1px solid #fff;
        background-color: #73a3d4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        &:hover {
            background-color: #5182b3;
        }
    }
    .product {
        margin-top: 20px;
        background-color: #fff;
        > .h2 {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }
        > .text {
            text-align: center;
            margin-top: 10px;
            font-size: 14px;
            color: #666;
        }
        > .p_list {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            .p_item {
                width: calc((100% - 80px) / 4);
                display: flex;
                flex-direction: column;
                align-items: center;
                > img {
                    width: 100%;
                    height: 130px;
                    margin-bottom: 10px;
                }
                > .p {
                    font-size: 14px;
                    font-weight: bold;
                }
                > .des {
                    height: 32px;
                    font-size: 12px;
                    color: #666;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>