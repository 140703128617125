<template>
    <div class="content">
        <div class="left">
            <LeftMenu titleName="仪器设备" :titleIconUrl="titleIconUrl" :subMenuData="menuList" @onDetail="checkList" :activeIndex="activeIndex" />
        </div>
        <div class="right">
            <div v-if="dataType =='uav'">
                <UavTopicPage />
            </div>
            <List v-else :dataType="dataType" :typeName="typeName" />
        </div>
    </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import UavTopicPage from "./uavTopicPage"
import List from './list.vue'
import { equipmentMenuList } from '@/mockData'
export default {
    name: "BaseData",
    components: {
        LeftMenu,
        List,
        UavTopicPage
    },
    data() {
        return {
            menuList: [],
            dataType: '',
            typeName: '',
            activeIndex: 0,
            titleIconUrl: require("../../assets/basedata/icon_basedata.png")
        }
    },
    mounted() {
        this.$request("/dictionary/getDictionariesByType", {
            method: "post",
            data: {
                dictType: "equipment_type"
            }
        }).then(res => {
            if (res.errorCode == 0) {
                this.baseData = null
                this.menuList = res.data.list.map((item, index) => {
                    return {
                        level: "2",
                        name: item.dictValue,
                        dictKey: item.dictKey,
                        id: item.dictKey
                    }
                })
                this.menuList.unshift({
                    level: "2",
                    name: "全部",
                    dictKey: "",
                    id: 0
                })
            }
        })
        if (this.$route.query?.type == 'uav') {
            this.dataType = 'uav'
            this.activeIndex = equipmentMenuList.findIndex(item => item.dictKey == this.dataType)
        }
    },
    methods: {
        checkList(data) {
            this.dataType = data.dictKey
            // 切换左侧菜单，给当前路由添加类型标识，解决 无人机库列表进入详情后，返回列表时，界面表更为全部列表数据
            this.$router.push({
                query: {
                    ...this.$router.query,
                    type: data.dictKey
                }
            })
            this.typeName = data.dictValue
        }
    }

}
</script>

<style lang="less" scoped>
.content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 0 30px 0;
    // margin: 0 auto;
    > .left {
        width: 280px;
        margin-right: 20px;
    }
    > .right {
        flex: 1;
    }
}
</style>