<!-- 视频组件 -->
<template>
    <div class="c_modal">
        <div class="c_modal_box">
            <img class="close" @click="closePop" src="../../assets/equipment/closeBtn.png" width="40" height="40" />
            <video style="width:100%" src="https://www.iocean.cn/attachment/oceanAppFile/normal/media/uav.mp4" autoplay="" controls=""></video>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
        closePop() {
            this.$emit("closePop")
        }
    }

}

</script>
<style lang='less' scoped>
.c_modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    .c_modal_box {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: auto;
        background-color: rgba(255, 255, 255, 0.3);
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 5px;
        .close {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            z-index: 100;
        }
    }
}
</style>