<template>
    <div class="listCon">
        <div class="search">
            <!-- @submit.native.prevent 处理回车搜索时，路由多问号的问题 -->
            <el-form :model="searchForm" ref="searchForm" label-width="100px" @submit.native.prevent>
                <el-row :gutter="10" type="flex">
                    <el-col :span="10">
                        <el-form-item label="查找数据" prop="name">
                            <el-input v-model="searchForm.name" placeholder="请输入要查找的数据" size="small" @change="fetchData('search')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <div class="handle">
                            <!-- @click="fetchData('search')" -->
                            <el-button type="primary" size="small">查询</el-button>
                            <el-button type="primary" plain size="small" @click="reset">重置</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="titlebar">
            <div class="titlename">{{typeName ? typeName : "全部"}}</div>
            <div class="total">共<span>{{pagination?.total}}</span>条数据</div>
        </div>
        <div class="cardList">
            <div class="empty" v-if="!baseData.length">
                <img src="../../assets/image/common/emtyp.png" />
                暂无数据
            </div>
            <div class="cardItem" v-else v-for="(item,index) in baseData" :key="index">
                <InfoCard :itemData="item" @toCheckData="toCheckData(item)" @toSign="toSign(item)" />
            </div>
        </div>
        <Page :pagination="pagination" @handleCurrentChange="handleCurrentChange" />
    </div>
</template>

<script>
import InfoCard from './infoCard'
import Page from '@/components/Page'
import { equipmentList } from '@/mockData'
export default {
    name: "List",
    props: ["dataType", "typeName"],
    components: {
        InfoCard,
        Page
    },
    data() {
        return {
            searchForm: {
                name: '',
            },
            pagination: {
                current: 1,
                pageSize: 12,
                total: 4
            },
            baseData: [],
        }
    },
    watch: {
        dataType: function () {
            this.fetchData()
        },
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        prevClick(val) {
            this.pagination.current = val
            this.fetchData()
        },
        handleCurrentChange(val) {
            this.pagination.current = val
            this.fetchData()
        },
        fetchData(type) {
            if (type == 'search') {
                this.pagination.current = 1
            }
            let params = {
                ...this.pagination,
                type: this.dataType,
                name: this.searchForm.name
            }
            this.$request("/equipment/getEquipmentPagingList", {
                method: "post",
                data: params
            }).then(res => {
                if (res.errorCode == 0) {
                    this.baseData = null
                    this.baseData = res.data.list.map((item, index) => {
                        return {
                            id: item.id,
                            name: item.name,
                            url: [
                                { httpAccessUrl: item.equipmentImageAttachment?.httpAccessUrl }
                            ],
                            type: item.type,
                            typeValue: item.typeValue,
                            content: item.description
                        }
                    })
                    this.pagination = res.data.pagination
                }
            })
        },
        reset() {
            this.pagination.current = 1
            this.searchForm.name = ''
            this.fetchData()
        },
        toCheckData(item) {
            // 查看数据
            this.$router.push({
                path: "/main/equipment/equipmentDetail",
                query: {
                    id: item.id
                }
            })
        },
        toSign(item) {
            // 订阅数据
        }
    }
}
</script>

<style lang="less" scoped>
.listCon {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding-top: 20px;
    .search {
        .handle {
            padding-top: 5px;
        }
        /deep/ .el-range-separator {
            padding: 0;
        }
    }
    > .titlebar {
        display: flex;
        height: 50px;
        background-color: #f5f6fa;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        margin: 0 20px 35px 20px;
        > .titlename {
            color: #1d78cd;
            font-size: 18px;
            font-weight: bold;
        }
        > .total {
            font-size: 16px;
            color: #666666;
            > span {
                color: #f3a611;
                margin: 0 5px;
            }
        }
    }
    > .cardList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 10px;
        > .empty {
            width: 100%;
            font-size: 16px;
            color: #666;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            > img {
                width: 120px;
                margin-bottom: 15px;
            }
        }
        > .cardItem {
            width: calc((100% - 90px) / 3);
            margin: 0px 15px 30px 15px;
        }
    }
}
</style>